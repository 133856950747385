<template>
	<v-container class="mt-5 ml-2">
	  <v-row>
	  	<v-col cols="12">
        <v-card class="shadowCard">
          
          <v-card-title class="text-subtitle-1">
            Panel Capacitación Usuario

            <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnos  , 'DIPLOMA_FINALIZA')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getSolicitudes()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          
          </v-card-title>
          
          <v-card-text>
          	<!-- Top -->
            <v-row justify="space-between">

            	<v-col cols="12" md="4">
	            	<v-radio-group
						      v-model="opcionData"
						      row
						    >
						      <v-radio
						        label="Fast"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="Inbi"
						        :value="1"
						      ></v-radio>
						    </v-radio-group>
              </v-col>


              <v-col cols="12" md="6">
                <v-text-field
                  v-model="search"
                  filled
                  dense
                  label="Buscar"
                ></v-text-field>    
              </v-col>
            </v-row>

             

            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="opcionEstatus"
                  row
                >
                  <v-radio
                    label="Nulo"
                    :value="0"
                    color="red"
                  ></v-radio>
                  <v-radio
                    label="Práctico"
                    :value="1"
                    color="purple"
                  ></v-radio>
                  <v-radio
                    label="Teórico"
                    :value="2"
                    color="orange"
                  ></v-radio>
                  <v-radio
                    label="Todos"
                    :value="3"
                    color="green"
                  ></v-radio>
                </v-radio-group>
              </v-col>
             
            </v-row>

            <tablasCapaTeacher :headers="filterHeader" :items="filterTeachers" :search="search"/>
          </v-card-text>
        </v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters, mapActions }    from "vuex";
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import tablasCapaTeacher     from '@/components/capacitacion_teacher/tablasCapaTeacher.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {
  	components:{
      Alerta,
      carga,
      tablasCapaTeacher
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,
      search:'',
      headersFast: [],
      headersInbi:[],
      grupos: [],
      teachers:[],
      opcionData: 2,

      dialogFiltro: false,

      headersNivel: [
        { text: 'Nivel',     value: 'idnivel' },
      ],
      selectNivel:[],
      niveles:[
        { idnivel: 1 },
        { idnivel: 2 },
        { idnivel: 3 },
        { idnivel: 4 },
        { idnivel: 5 },
        { idnivel: 6 },
        { idnivel: 7 },
        { idnivel: 8 },
      ],

      niveles2:[
        { idnivel: 9 },
        { idnivel: 10 },
        { idnivel: 11 },
        { idnivel: 12 },
        { idnivel: 13 },
        { idnivel: 14 },
        { idnivel: 15 },
        { idnivel: 16 },
      ],
      opcionEstatus: 4
    }),

    async created () {
		  await  this.initialize()
    },

    watch:{
      selectNivel( value ){
        console.log( value )
      }
    },

    methods: {
      initialize () {
        this.teachers = []
        this.cargar = true

        return this.$http.get('panel.capacitacion.teacher').then(response=>{
          
          this.headersFast  = response.data.headersFast
          this.headersInbi  = response.data.headersInbi
          this.teachers     = response.data.teachersERP.filter( el => { return el.id_usuario == this.getdatosUsuario.iderp })
          this.cargar       = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },

    computed: {
    	...mapGetters('login',['getdatosUsuario','getLogeado', 'getEscuela','getEstatusLlamada','getTareas','getNuevosProspectos']),

      filterHeader () {
        const niveles_fast  = [ 1,2,3,4,5,6,7,8,9,10,11,12,13,14 ]
        const niveles_inbi  = [ 1,2,3,4,5,6,7,8,9,10,11,12,13,14 ]
        const niveles_teens = [ 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15 ]

        if( this.opcionData == 2 ){
          if( this.selectNivel.length ){
            let headerPersonalizado = [{ text: 'Teacher' , value: 'nombre_completo' }]
            
            for( const i in niveles_fast ){
              if( this.selectNivel.includes( niveles_fast[i] )  && niveles_fast[i] < 15 ){
                headerPersonalizado.push({ 
                  text: `Nivel ${niveles_fast[i]}`, 
                  value: `nivel_${niveles_fast[i]}_fast`, 
                  class: 'header_fast white--text'
                })
              }
            }
            return headerPersonalizado
          }else{
        	  return this.headersFast
          }
        }
        else{

          if( this.selectNivel.length ){
            let headerPersonalizado = [{ text: 'Teacher' , value: 'nombre_completo' }]
            for( const i in niveles_teens ){
              if( this.selectNivel.includes( niveles_teens[i] )  && niveles_teens[i] < 15 ){
                
                headerPersonalizado.push({ 
                  text: `Nivel ${niveles_teens[i]}`, 
                  value: `nivel_${niveles_teens[i]}_inbi`, 
                  class: 'header_inbi white--text'
                })

                headerPersonalizado.push({ 
                  text: `Nivel ${niveles_teens[i]}`, 
                  value: `nivel_${niveles_teens[i]}_teens`, 
                  class: 'header_teens white--text'
                })
              }
            }
            return headerPersonalizado
          }else{
            return this.headersInbi
          }
        }
      },

      filterTeachers( ){
        const niveles_fast  = [ 1,2,3,4,5,6,7,8,9,10,11,12,13,14  ]
        const niveles_inbi  = [ 1,2,3,4,5,6,7,8,9,10,11,12,13,14  ]
        const niveles_teens = [ 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16 ]

        if( this.opcionEstatus < 4 ){
          if( this.opcionData == 2 ){
            return this.teachers.filter( el => { return el.nivel_1_fast == this.opcionEstatus || el.nivel_2_fast == this.opcionEstatus || el.nivel_3_fast == this.opcionEstatus || el.nivel_4_fast == this.opcionEstatus || el.nivel_5_fast == this.opcionEstatus || el.nivel_6_fast == this.opcionEstatus || el.nivel_7_fast == this.opcionEstatus || el.nivel_8_fast == this.opcionEstatus || el.nivel_9_fast == this.opcionEstatus || el.nivel_10_fast == this.opcionEstatus || el.nivel_11_fast == this.opcionEstatus || el.nivel_12_fast == this.opcionEstatus || el.nivel_13_fast == this.opcionEstatus || el.nivel_14_fast == this.opcionEstatus })
          }else{
            return this.teachers.filter( el => { return el.nivel_1_inbi == this.opcionEstatus || el.nivel_1_teens == this.opcionEstatus || el.nivel_2_inbi == this.opcionEstatus || el.nivel_2_teens == this.opcionEstatus || el.nivel_3_inbi == this.opcionEstatus || el.nivel_3_teens == this.opcionEstatus || el.nivel_4_inbi == this.opcionEstatus || el.nivel_4_teens == this.opcionEstatus || el.nivel_5_inbi == this.opcionEstatus || el.nivel_5_teens == this.opcionEstatus || el.nivel_6_inbi == this.opcionEstatus || el.nivel_6_teens == this.opcionEstatus || el.nivel_7_inbi == this.opcionEstatus || el.nivel_7_teens == this.opcionEstatus || el.nivel_8_inbi == this.opcionEstatus || el.nivel_8_teens == this.opcionEstatus || el.nivel_9_inbi == this.opcionEstatus || el.nivel_9_teens == this.opcionEstatus || el.nivel_10_inbi == this.opcionEstatus || el.nivel_10_teens == this.opcionEstatus || el.nivel_11_inbi == this.opcionEstatus || el.nivel_11_teens == this.opcionEstatus || el.nivel_12_inbi == this.opcionEstatus || el.nivel_12_teens == this.opcionEstatus || el.nivel_13_inbi == this.opcionEstatus || el.nivel_13_teens == this.opcionEstatus || el.nivel_14_inbi == this.opcionEstatus || el.nivel_14_teens == this.opcionEstatus || el.nivel_15_inbi == this.opcionEstatus || el.nivel_15_teens == this.opcionEstatus || el.nivel_16_inbi == this.opcionEstatus || el.nivel_16_teens == this.opcionEstatus })
          }
        }else{
          return this.teachers
        }
      }
    },
  }
</script>